import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../Spinner";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import "./Optimization.css";

function Optimization(props) {
  const [loading, setLoading] = useState(false);
  const [optimizationResults, setOptimizationResults] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();

  const backendUrl = process.env.REACT_APP_BACKEND_URL; // Get backend URL from environment variable

  const optimization = async (data) => {
    console.log("Session ID:", props.sessionId);
    console.log("Backtest Data:", props.backTestData);
    console.log("Number of trials:", data.numTrials);

    try {
      setLoading(true);
      const response = await axios.post(`${backendUrl}/optimize/`, {
        session_id: props.sessionId,
        sel_ticker: props.backTestData.sel_ticker,
        check_flags: props.backTestData.check_flags,
        num_trials: data.numTrials,
      });
      setLoading(false);
      console.log("Optimization Results:", response.data);
      setOptimizationResults(response.data);
      console.log("State Optimization Results:", optimizationResults);
      toast.success("Optimization Successful", { duration: 4000 });
      console.log("type of optimizationResults:", typeof optimizationResults);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error Status:", error.response.status);
        console.error("Error Data:", error.response.data);
        toast.error(error.response.data.detail, { duration: 4000 });
        setLoading(false);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        setLoading(false);
        toast.error("No response recieved", { duration: 4000 });
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        setLoading(false);
        toast.error(error.message.detail, { duration: 4000 });
      }
    }
  };

  const renderObject = (obj, level = 0) => {
    return (
      <div className="">
        {Object.entries(obj).map(([key, value]) => (
          <div key={key} className="">
            <strong>{key}:</strong>{" "}
            {typeof value === "object" && value !== null
              ? renderObject(value, level + 1)
              : value !== null
              ? value.toString()
              : "null"}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="mt-2">
      <Toaster />
      {loading && <Spinner />}
      {props.sessionId && (
        <>
          <form onSubmit={handleSubmit(optimization)}>
            <hr />
            <h3>Run Optimization</h3>
            <div className="row my-3 align-items-center justify-content-between">
              <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
                <div>
                  {" "}
                  <label className="small">Enter Number of Trials</label>
                  <input
                    type="number"
                    {...register("numTrials", {
                      required: "Number of trials is required",
                      min: {
                        value: 1,
                        message: "The number of trials must be at least 1",
                      },
                      max: {
                        value: 15,
                        message: "The number of trials cannot exceed 15",
                      },
                    })}
                    className="form-control small inputBoxWidth"
                    placeholder="select value 0-15"
                  />
                </div>
                <div className="text-danger small">
                  {errors.numTrials?.message}
                </div>
              </div>
            </div>
            <div className="my-3">
              <button className="btn border text-white mt-3" type="submit">
                Start Optimization
              </button>
            </div>
          </form>
          {optimizationResults && <h3>Optimization Results</h3>}
          {optimizationResults && <h4>Best Score:</h4>}
          {optimizationResults && optimizationResults.best_score}

          {optimizationResults && <h4>Best Parameters:</h4>}
          {optimizationResults &&
            Object.entries(optimizationResults.best_params).map(
              ([key, value]) => (
                <p key={key}>
                  <span>{key}:</span> {value}
                </p>
              )
            )}
          {optimizationResults && <h4>Best Trial:</h4>}
          {optimizationResults?.best_trial &&
            renderObject(optimizationResults.best_trial)}
          {/* {optimizationResults && Object.entries(optimizationResults.best_trial).map(([key, value]) =>
         { if (typeof value !== "object") {
           return  (<span className="me-2" key={key}>
              <strong>{key}:</strong> {value.toString()}
            </span>)
          }  
        })}  */}
        </>
      )}
    </div>
  );
}

export default Optimization;
