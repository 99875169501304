import React from "react";
import "./SignIn.css";
import google from "../../Assets/google.svg";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function SignIn() {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();

  const login = async (data) => {
    console.log("sign in is clicked");
    console.log("data from react form", data);

    const backendUrl = process.env.REACT_APP_BACKEND_URL; // Get backend URL from the environment variable
    try {
      const response = await axios.post(`${backendUrl}/login/`, {
        email: data.email,
        password: data.password,
        otp: "abcd",
      });
      // setLoading(false);
      console.log("Response:", response.data);
      const accessToken = response.data.access_token;
      localStorage.setItem("accessToken", accessToken);
      toast.success("Login successful", { duration: 3000 });
      console.log("Login successful");
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error Status:", error.response.status);
        console.error("Error Data:", error.response.data);
        toast.error(error.response.data.detail, { duration: 4000 });
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        // setLoading(false);
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        toast.error(error.message.detail, { duration: 4000 });
      }
    }
  };

  return (
    <>
      <Toaster />
      <div style={{ background: "black" }}>
        <div className="container d-flex justify-content-center py-5 text-white">
          <div className="col-md-6 pt-2">
            <form
              onSubmit={handleSubmit(login)}
              className="p-4 p-md-5 border borderRadius bg-dark border"
            >
              <div className="mb-2">
                <h3 className="fw-bold">Sign In</h3>
              </div>

              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  className="form-control"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, // regex for basic email format
                      message: "Enter a valid email address",
                    },
                  })}
                />
                <div className="text-danger small">{errors.email?.message}</div>
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <div className="text-danger small">
                  {errors.password?.message}
                </div>
              </div>
              <button
                className="w-100 btn btn-lg border text-white mb-4"
                type="submit"
              >
                Sign in
              </button>
              <div className="text-center mb-3">or sign in with</div>
              <button className="w-100 btn btn-lg border text-white">
                <img src={google} alt="" className="me-2 mb-1" />
                <span
                  className="fw-bold"
                  style={{ color: "rgb(28, 158, 181)" }}
                >
                  Google
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
