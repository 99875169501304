import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Optimization from "./Optimization";
import "./BackTest.css";
import Spinner from "../Spinner";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DisplayBackTestResults from "./DisplayBackTestResults";

function BackTest() {
  const [display, setDisplay] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [backTestData, setBackTestData] = useState(null);
  const [backTestResults, setBackTestResults] = useState(null);
  const [displayResults, setDisplayResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    if (sessionId !== null) {
      console.log("Updated sessionId:", sessionId);
    }
  }, [sessionId]);

  const startBackTest = (data) => {
    console.log("real backtest data is: ", data);
    if (endDate <= startDate) {
      toast.error("Start date must be greater than end date", {
        duration: 4000,
      });
      return;
    }
    // Set the backTestData with the form data
    setBackTestData({
      model_choice: data.model_choice,
      trading_type: data.trading_type,
      leverage_enable: data.leverage_enable,
      leverage: data.leverage,
      pyramiding_enable: data.pyramiding_enable,
      pyramid_amt_pct: data.pyramid_amt_pct,
      pyramid_scale: data.pyramid_scale,
      tsl_en: data.tsl_en,
      sl_perc_long: data.sl_perc_long,
      sl_perc_short: data.sl_perc_short,
      tp_perc_long: data.tp_perc_long,
      tp_perc_short: data.tp_perc_short,
      direction: data.direction,
      method_type: data.method_type,
      price_type: data.price_type,
      pos_size_value: data.pos_size_value,
      check_flags: {
        bband: data.bband,
        rsi: data.rsi,
        macd: data.macd,
        obv: data.obv,
        ema: data.ema,
        supertrend: data.supertrend,
        sma: data.sma,
        get_sentiment: data.get_sentiment,
      },
      sel_ticker: data.sel_ticker,
      sel_tf: data.sel_tf,
      start_date: startDate,
      end_date: endDate,
    });
  };
  const backendUrl = process.env.REACT_APP_BACKEND_URL; // Get backend URL from environment variable
  useEffect(() => {
    if (backTestData) {
      console.log("backTestData has been set:", backTestData);
      const postData = async () => {
        setLoading(true);
        try {
          const response = await axios.post(`${backendUrl}/run-backtest/`,
            backTestData
          );
          setLoading(false);
          toast.success("Backtesting Succesfull! You may run optimization", {
            duration: 4000,
          });
          console.log("Response:", response.data);
          const { backtest_results, trades_data, session_id } = response.data;
          setBackTestResults(response.data);
          console.log("backtest result is: ", backTestResults);
          setSessionId(session_id);
        } catch (error) {
          if (error.response) {
            // Server responded with a status other than 2xx
            console.error("Error Status:", error.response.status);
            console.error("Error Data:", error.response.data);
            setLoading(false);
            toast.error("Response error", { duration: 4000 });
          } else if (error.request) {
            // Request was made but no response received
            console.error("No response received:", error.request);
            setLoading(false);
            toast.error("No response recieved. Please contact admin", {
              duration: 4000,
            });
          } else {
            // Something else caused the error
            console.error("Error:", error.message);
            setLoading(false);
            toast.error(error.message, { duration: 4000 });
          }
        }
      };

      postData(); // Call postData only after backTestData has been updated
    }
  }, [backTestData]);

  return (
    <div>
      <Toaster />
      <form onSubmit={handleSubmit(startBackTest)}>
        <div className="row  align-items-center justify-content-center mx-auto">
          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-evenly ">
            <div>
              {" "}
              <label className="small">Select Ticker Symbol:</label>
              <select
                {...register("sel_ticker")}
                className="form-control small inputBoxWidth"
              >
                <option value="BTCUSDT">BTCUSDT</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6  d-flex flex-column justify-content-center align-items-center align-items-xl-evenly">
            <div>
              <label className="small">Select Timeframe:</label>
              <select
                {...register("sel_tf")}
                className="form-control small inputBoxWidth"
              >
                <option value="1h">1h</option>
                <option value="1min">1min</option>
                <option value="5min">5min</option>
                <option value="15min">15min</option>
                <option value="30min">30min</option>
                <option value="4h">4h</option>
                <option value="12h">12h</option>
                <option value="1day">1 day</option>
                <option value="1week">1 week</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-evenly">
            <div>
              <label className="small">Start date</label>
              <div>
                <DatePicker
                  className="form-control small inputBoxWidth"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-evenly ">
            <div>
              <label className="small">End date</label>
              <div>
                <DatePicker
                  className="form-control small inputBoxWidth"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4 align-items-center justify-content-start mx-auto">
          <h3>Setup</h3>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              <label className="small">Model Choice:</label>
              <select
                {...register("model_choice")}
                className="form-control small inputBoxWidth"
              >
                <option value="Custom Parameters">Custom Parameters</option>
                <option value="Pre-trained model">Pre-trained model</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              <label className="small">Select Trading Type:</label>
              <select
                {...register("trading_type")}
                className="form-control small inputBoxWidth"
              >
                <option value="futures">Futures</option>
                <option value="spot">Spot</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("leverage_enable")}
                id="leverage_enable"
              />
              <label className="form-check-label" for="leverage_enable">
                Enable Leverage
              </label>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">Leverage</label>
              <input
                type="number"
                {...register("leverage", {
                  required: "leverage is required",
                  min: {
                    value: 1,
                    message: "Value must be at least 1",
                  },
                  max: {
                    value: 20,
                    message: "Value cannot exceed 20",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 0-20"
              />
            </div>
            <div className="text-danger small">{errors.leverage?.message}</div>
          </div>
        </div>

        <div className="row mt-4 align-items-center justify-content-start mx-auto">
          <h3>Strategy Parameters</h3>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("pyramiding_enable")}
                id="pyramiding_enable"
              />
              <label className="form-check-label" for="pyramiding_enable">
                Enable Pyramiding
              </label>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">Pyramid Scale:</label>
              <input
                type="number"
                {...register("pyramid_scale", {
                  required: "Pyramid scale is required",
                  min: {
                    value: 1,
                    message: "Value must be at least 1",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 1 or above"
              />
            </div>
            <div className="text-danger small">
              {errors.pyramid_scale?.message}
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">pyramid_amt_pct:</label>
              <input
                type="number"
                {...register("pyramid_amt_pct", {
                  required: "Pyramid scale is required",
                  min: {
                    value: 1,
                    message: "Value must be at least 1",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 1 or above"
              />
            </div>
            <div className="text-danger small">
              {errors.pyramid_amt_pct?.message}
            </div>
          </div>
        </div>

        <div className="row my-3 align-items-center justify-content-between">
          <h5 className="fw-light">Position Size Calculator</h5>
          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              <label className="small">Direction:</label>
              <select
                {...register("direction")}
                className="form-control small inputBoxWidth"
              >
                <option value="Both">Both</option>
                <option value="Long">Long</option>
                <option value="Short">Short</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">Position Type:</label>
              <select
                {...register("method_type")}
                className="form-control small inputBoxWidth"
              >
                <option value="Fixed">Fixed</option>
                <option value="Dynamic">Dynamic</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              <label className="small">Price Type:</label>
              <select
                {...register("price_type")}
                className="form-control small inputBoxWidth"
              >
                <option value="Percentage">Percentage</option>
                <option value="Quote">Quote</option>
                <option value="Base">Base</option>
              </select>
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">Position size value (%)</label>
              <input
                type="number"
                {...register("pos_size_value", {
                  required: "Position size value is required",
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                  max: {
                    value: 100,
                    message: "Value must be less than 100",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 0 or above"
              />
            </div>
            <div className="text-danger small">
              {errors.pos_size_value?.message}
            </div>
          </div>
        </div>

        {/* new row */}
        <div className="row my-3 align-items-center justify-content-between">
          <h5 className="fw-light">Indicator Settings</h5>
          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">Take Profit Long [%]</label>
              <input
                type="number"
                {...register("tp_perc_long", {
                  required: "sl_perc_long is required",
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 0 or above"
              />
            </div>
            <div className="text-danger small">
              {errors.tp_perc_long?.message}
            </div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">Take Profit Short [%]</label>
              <input
                type="number"
                {...register("tp_perc_short", {
                  required: "tp_perc_short is required",
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 0 or above"
              />
            </div>
            <div className="text-danger">{errors.tp_perc_short?.message}</div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">SL Percentage Long [%]</label>
              <input
                type="number"
                {...register("sl_perc_long", {
                  required: "sl_perc_long is required",
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 0 or above"
              />
            </div>
            <div className="text-danger">{errors.sl_perc_long?.message}</div>
          </div>

          <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div>
              {" "}
              <label className="small">SL Percentage Short [%]</label>
              <input
                type="number"
                {...register("sl_perc_short", {
                  required: "sl_perc_short is required",
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                })}
                className="form-control small inputBoxWidth"
                placeholder="select value 0 or above"
              />
            </div>
            <div className="text-danger">{errors.sl_perc_short?.message}</div>
          </div>
          <div className="my-md-4 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("tsl_en")}
                id="tsl_en"
              />
              <label className="form-check-label" for="tsl_en">
                Trailing SL Enable/Disable
              </label>
            </div>
          </div>
        </div>
        {/* new row */}
        <div className="">
          <h5 className="fw-light">Select Indicator</h5>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("bband")}
              id="bband"
            />
            <label className="form-check-label" for="bband">
              BBand
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("rsi")}
              id="rsi"
            />
            <label className="form-check-label" for="rsi">
              RSI
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("macd")}
              id="macd"
            />
            <label className="form-check-label" for="macd">
              MACD
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("obv")}
              id="obv"
            />
            <label className="form-check-label" for="obv">
              OBV
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("ema")}
              id="ema"
            />
            <label className="form-check-label" for="ema">
              EMA
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("supertrend")}
              id="supertrend"
            />
            <label className="form-check-label" for="supertrend">
              Supertrend
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="sma"
              {...register("sma")}
            />
            <label className="form-check-label" for="sma">
              SMA
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("get_sentiment")}
              id="get_sentiment"
            />
            <label className="form-check-label" for="get_sentiment">
              Sentimental Analysis
            </label>
          </div>
        </div>
        <div className="my-3">
          <button
            className="btn border text-white"
            type="submit"
            onClick={() => {
              setDisplayResults(1);
            }}
          >
            Start Backtest
          </button>
        </div>
      </form>
      <hr />
      <div>
        <DisplayBackTestResults backTestResults={backTestResults} />
      </div>

      <div>
        <Optimization sessionId={sessionId} backTestData={backTestData} />
      </div>
    </div>
  );
}

export default BackTest;
