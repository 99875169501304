import React from 'react'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from "axios";

function ConfirmEmail(props) {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();


  const verification = async (data)=> {
    console.log(data);
    
    const backendUrl = process.env.REACT_APP_BACKEND_URL; // Get backend URL from environment variable
    try {
      const response = await axios.post(
        `${backendUrl}/verify/`, // Use the dynamic backend URL
        {
        email: props.email,
        verification_code: data.verificationCode,
        }
      );
      // setLoading(false);
      console.log("Response:", response.data);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error Status:", error.response.status);
        console.error("Error Data:", error.response.data);
        // setLoading(false);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        // setLoading(false);
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        // setLoading(false);
      }
    }
  }


  return (
    <div style={{background: "black"}}>
    <div className='container d-flex justify-content-center py-5 text-white'>
        <div className="col-md-6 pt-2">
        <form  onSubmit={handleSubmit(verification)} className="p-4 p-md-5 border borderRadius bg-dark border text-center">
              <div className="mb-4">
                <h3 className="fw-bold text-center">Confirm Email</h3>
              </div>
              <div>
                <p className='text-start'>
                Enter your verification code
                </p>
              </div>
              <div>
              <input {...register("verificationCode", {
                    required: "Verfication code is required"
                  })} className='text-start w-100 mb-3 form-control' type="password" placeholder='Verification Code'/>
              </div>
              <button
                className="w-100 btn btn-lg border text-white"
                type="submit"
              >
                Confirm
              </button>
            </form>
            </div>
    </div>
    </div>
  )
}

export default ConfirmEmail
