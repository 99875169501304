import React, { useState, useEffect } from "react";
import "./LiveTrading.css";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import BootstrapSpinner from "./BootstrapSpinner";

function LiveTrading(props) {
  const [quoteCurrency, setQuoteCurrency] = useState("USDT");
  const [loading, setLoading] = useState(false);

  const handleCurrencyChange = (event) => {
    console.log(event.target.value);
    props.setQuoteCurrency(event.target.value);
  };

  // useEffect(() => {
  //     const liveData = async () => {
  //       setLoading(true);
  //       try {
  //         const balances = await axios.post(
  //           "http://localhost:8000/balances/",
  //           {balance_request: { quote_currency: quoteCurrency}, user: { email: props.userResponse.email, username: props.userResponse.username}}
  //         );
  //         // setLoading(false);
  //         // toast.success("Backtesting Succesfull! You may run optimization", {
  //         //   duration: 4000,
  //         // });
  //         console.log("balances:", balances.data);
  //         setBalances(balances)
  //         setLoading(false)

  //         const liveMode = await axios.post(
  //           "http://localhost:8000/live_mode/",
  //           { email: props.userResponse.email, username: props.userResponse.username}
  //         );
  //         console.log("live mode", liveMode)
  //         setOpenPosition(liveMode)

  //       } catch (error) {
  //         if (error.response) {
  //           // Server responded with a status other than 2xx
  //           console.error("Error Status:", error.response.status);
  //           console.error("Error Data:", error.response.data);
  //           // setLoading(false);
  //           toast.error("Response error", { duration: 4000 });
  //         } else if (error.request) {
  //           // Request was made but no response received
  //           console.error("No response received:", error.request);
  //           // setLoading(false);
  //           toast.error("No response recieved. Please contact admin", {
  //             duration: 4000,
  //           });
  //         } else {
  //           // Something else caused the error
  //           console.error("Error:", error.message);
  //           // setLoading(false);
  //           toast.error(error.message, { duration: 4000 });
  //         }
  //       }
  //     };

  //     liveData();
  //     console.log(balances)
  // }, [quoteCurrency]);

  return (
    <div>
      <Toaster />
      <div className="container marginLeft">
        <label className="small">Find Balance</label>
        <select
          value={props.quoteCurrency} // Bind the state value to the select
          onChange={handleCurrencyChange} // Set the change handler
          className="form-control small inputBoxWidth"
        >
          <option value="USD">USD</option>
          <option value="USDT">USDT</option>
        </select>
      </div>

      <div className="container marginLeft fs-2 mt-4 mb-3 fw-bold">
        Account Balances ({props.quoteCurrency})
      </div>

      <div className="container row justify-content-evenly">
        <div
          className="col-md-3 border px-0 my-3 justify-content-center"
          style={{
            width: "230px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "230px",
            backgroundColor: "rgb(76, 175, 80)",
            borderRadius: "10px",
          }}
        >
          <div className="text-center">
            <div className="my-4 fs-3">Total Balance</div>
            {loading ? (
              <BootstrapSpinner />
            ) : (
              <div className="fw-bold fs-2">
                $
                {props.balances?.data?.total_balance !== undefined
                  ? props.balances.data.total_balance.toFixed(2)
                  : "N/A"}
              </div>
            )}
          </div>
        </div>

        <div
          className="col-md-3 border px-0 my-3 justify-content-center"
          style={{
            width: "230px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "230px",
            backgroundColor: "rgb(76, 175, 80)",
            borderRadius: "10px",
          }}
        >
          <div className="text-center">
            <div className="my-4 fs-3">Free Balance</div>
            {loading ? (
              <BootstrapSpinner />
            ) : (
              <div className="fw-bold fs-2">
                $
                {props.balances?.data?.free_balance !== undefined
                  ? props.balances.data.free_balance.toFixed(2)
                  : "N/A"}
              </div>
            )}
          </div>
        </div>

        <div
          className="col-md-3 border px-0 my-3 justify-content-center"
          style={{
            width: "230px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "230px",
            backgroundColor: "rgb(76, 175, 80)",
            borderRadius: "10px",
          }}
        >
          <div className="text-center">
            <div className="my-4 fs-3">Used Balance</div>
            {loading ? (
              <BootstrapSpinner />
            ) : (
              <div className="fw-bold fs-2">
                $
                {props.balances?.data?.used_balance !== undefined
                  ? props.balances.data.used_balance.toFixed(2)
                  : "N/A"}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container marginLeft fs-3 mt-4 mb-3">
        Running Model: Pre-trained Model
      </div>

      <div className="container marginLeft mt-4 mb-3">
        <span className="fs-3">Open Position Summary</span>
        <div style={{ width: "82%" }} className="text-center my-2">
          <table className="table table-bordered text-white">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Position Type</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Long</td>
                <td>
                  {props.openPosition?.data?.open_positions_summary?.Count[0]}
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Short</td>
                <td>
                  {props.openPosition?.data?.open_positions_summary?.Count[1]}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="container marginLeft fs-3 mt-4 mb-3">
        Current Open Positions
      </div>
      {props.openPosition?.data?.current_open_position &&
        Object.entries(props.openPosition.data.current_open_position).map(
          ([key, item], index) => {
            return (
              <div
                key={index}
                className="my-4 container marginLeft"
                style={{ marginRight: "190px" }}
              >
                <h4>Position: {key}</h4>
                <div className="row justify-content-start">
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Side: {item.side}</li>
                      <li className="list-group-item">
                        Entry Price: {item.entry_price}
                      </li>
                      <li className="list-group-item">
                        Filled on: {item.formatted_date}
                      </li>
                      <li className="list-group-item">Size: {item.size}</li>
                      <li className="list-group-item">
                        Unrealized Funding: {item.unrealized_funding}
                      </li>
                      <li className="list-group-item">
                        PnL Currency: {item.pnl_currency}
                      </li>
                      <li className="list-group-item">
                        Max Leverage: {item.leverage}
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-2">
                    <div>Unrealized PnL</div>
                    <div className="fs-3">
                      $
                      {item.pnl_value !== undefined
                        ? item.pnl_value.toFixed(3)
                        : "N/A"}
                    </div>
                    <div
                      className={`small ${
                        item.pnl_percentage < 0 ? "text-danger" : "text-success"
                      }`}
                    >
                      <i
                        className={`bi bi-arrow-${
                          item.pnl_percentage < 0 ? "down" : "up"
                        }`}
                      ></i>
                      {item.pnl_percentage !== undefined
                        ? item.pnl_percentage.toFixed(2)
                        : "N/A"}
                      %
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
}

export default LiveTrading;
