import React from "react";

function DisplayBackTestResults(props) {
  let results = [];
  let trades_data = [];

  // Check if backtest_results is a string and parse it
  if (typeof props.backTestResults?.backtest_results === "string") {
    try {
      results = JSON.parse(props.backTestResults.backtest_results);
      console.log("Parsed backtest_results:", results);
    } catch (error) {
      console.error("Error parsing backtest_results:", error);
    }
  } else {
    results = props.backTestResults?.backtest_results || [];
  }

  // Check if backtest_results is a string and parse it
  if (typeof props.backTestResults?.trades_data === "string") {
    try {
      trades_data = JSON.parse(props.backTestResults.trades_data);
      console.log("Parsed trades_data:", trades_data);
    } catch (error) {
      console.error("Error parsing trades_data:", error);
    }
  } else {
    trades_data = props.backTestResults?.trades_data || [];
  }

  return (
    <div className="my-2">
      {props.backTestResults && <h3>BackTest Results</h3>}
      {results.map((item, index) => (
        <div key={index}>
          {Object.entries(item).map(([key, value]) => (
            <p key={key}>
              <span>{key}:</span> {value}
            </p>
          ))}
        </div>
      ))}
      {props.backTestResults && (
        <div className="">
        <div>
          <h4>Trades Data</h4>
        </div>

        <div
          className="table-responsive  border rounded-3"
          style={{ maxWidth: "700px", maxHeight: "400px" }}
        >
          <table className="table align-middle text-white">
            <thead>
              <tr className="text-center align-middle">
                <th scope="col">#</th>
                <th scope="col">Entry Date</th>
                <th scope="col">Entry Price</th>
                <th scope="col">Exit Date</th>
                <th scope="col">Exit Price</th>
                <th scope="col">Profits</th>
                <th scope="col">Commulative Returns</th>
                <th scope="col">Entry Type</th>
                <th scope="col">Exit Type</th>
                <th scope="col">Position Size</th>
                <th scope="col">Current Balance</th>
                <th scope="col">Profit/Loss</th>
                <th scope="col">Trade Side</th>
              </tr>
            </thead>
           
            <tbody>
              {trades_data.map((item, index) => (
                
                  <tr key={index}>
                    <td>{index + 1}</td>
                  {Object.entries(item).map(([key, value]) => (
                      <td>{value || "-"}</td>
                  ))}
                  </tr>
                
              ))}
            </tbody>
          </table>
        </div>
      </div>
      )}
      
    </div>
  );
}

export default DisplayBackTestResults;
