import React from "react";

function MetricCard({ title, value }) {
  return (
    <div
      className="col-md-3 border rounded-3 px-0 my-3"
      style={{
        width: "160px",
        height: "160px",
        maxHeight: "160px",
        maxWidth: "160px",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
    >
      <div className="d-flex align-items-center text-center h-50">
        <span>{title}</span>
      </div>
      <hr style={{ width: "100%", margin: 0 }} />
      <div className="d-flex align-items-center justify-content-center h-50">
        <span>
          {value === undefined ? (
            <div className="d-flex align-items-center justify-content-center">
              <div className="spinner-border-sm spinner-border me-2"></div>
            </div>
          ) : (
            <span className="fw-bold fs-3 text-primary">
              {value.toFixed(3)}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

export default MetricCard;
