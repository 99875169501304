import React from 'react'
import "./Dashboard.css"
import { useState, useEffect } from "react";
import DashboardDisplay from '../Components/Dashboard/DashboardDisplay'
import BackTest from '../Components/Dashboard/BackTest'
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import LiveTrading from '../Components/Dashboard/LiveTrading';
import Sidebar from '../Components/Dashboard/Sidebar';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [userResponse, setUserResponse] = useState("");
  const [quoteCurrency, setQuoteCurrency] = useState("USD");
  const [balances, setBalances] = useState();
  const [openPosition, setOpenPosition] = useState();
  const [display, setDisplay] = useState(1);

  // Load backend URL from environment variables
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  console.log("Backend Url: ", backendUrl);
  useEffect(() => {
    // Fetch user information and dashboard data from the backend API
    const fetchDashboardData = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        toast.error("Access token not found. Please log in.");
        return;
      }
      try {
        // Make a POST request to fetch user information
        console.log(accessToken);
        const userResponse = await axios.get(
          `${backendUrl}/verify_t/${accessToken}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log("user response: ", userResponse);
        setUserResponse(userResponse.data);

        // Make a POST request to fetch dashboard data
        const dashboardResponse = await axios.get(`${backendUrl}/dashboard`, {
          email: userResponse.data.email,
          username: userResponse.data.username,
        });
        console.log("dashboard response", dashboardResponse);
        setDashboardData(dashboardResponse.data);

        console.log("userResponse email", userResponse.data.email);
        // Make a POST request to fetch open positions data
        const liveMode = await axios.get(`${backendUrl}/live_mode`, {
          email: userResponse.data.email,
          username: userResponse.data.username,
        });
        console.log("userResponse email", userResponse.data.email);
        console.log("live mode", liveMode);
        setOpenPosition(liveMode);

        // Make a POST request to fetch balances data
        console.log("Quote currency", quoteCurrency);
        const balances = await axios.get(`${backendUrl}/balances`, {
          balance_request: { quote_currency: quoteCurrency },
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
        });
        // setLoading(false);
        // toast.success("Backtesting Succesfull! You may run optimization", {
        //   duration: 4000,
        // });
        console.log("balances:", balances.data);
        setBalances(balances);
        // setLoading(false)
      } catch (error) {
        if (error.response) {
          console.error("Error Status:", error.response.status);
          console.error("Error Data:", error.response.data);
          toast.error("Failed to fetch data. Please try again.");
        } else if (error.request) {
          console.error("No response received:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          console.error("Error:", error.message);
          toast.error("An error occurred. Please try again.");
        }
      }
    };

    fetchDashboardData();
  }, [dashboardData, quoteCurrency]);

  return (
    <>
      <Sidebar userResponse={userResponse} />
      <div className="container justify-content-center my-sm-0 my-4 py-5">
        <div className="text-center mx-auto">
          <h1
            className="fw-bold resHeading"
            style={{ fontSize: "var(--font-size-39xl)" }}
          >
            Dashboard
          </h1>
        </div>
        <div className="container my-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-3 rounded-3 scaleUp text-center my-2 my-xl-0 px-0">
              <button
                className={`btn shadow-none text-center d-block w-100 ${
                  display === 1
                    ? "text-danger border-danger"
                    : "text-white border"
                }`}
                onClick={() => {
                  setDisplay(1);
                }}
              >
                <div className="">Dashboard</div>
                <div className="small">
                  <em>Manage params & view trade</em>
                </div>
              </button>
            </div>

            <div className="col-md-3 rounded-3 scaleUp text-center my-2 my-xl-0 px-0">
              <button
                className={`btn shadow-none text-center d-block w-100 ${
                  display === 2
                    ? "text-danger border-danger"
                    : "text-white border"
                }`}
                onClick={() => {
                  setDisplay(2);
                }}
              >
                <div className="">Backtest & Optimize</div>
                <div className="small">
                  <em>Test Strategies Safely</em>
                </div>
              </button>
            </div>
            <div className="col-md-3 rounded-3 scaleUp text-center my-2 my-xl-0 px-0">
              <button
                className={`btn shadow-none text-center d-block w-100 ${
                  display === 3
                    ? "text-danger border-danger"
                    : "text-white border"
                }`}
                onClick={() => {
                  setDisplay(3);
                }}
              >
                <div className="">Live Mode</div>
                <div className="small">
                  <em>Real trading with funds</em>
                </div>
              </button>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        {display === 1 && <DashboardDisplay dashboardData={dashboardData} />}
        {display === 2 && <BackTest />}
        {display === 3 && (
          <LiveTrading
            setQuoteCurrency={setQuoteCurrency}
            quoteCurrency={quoteCurrency}
            openPosition={openPosition}
            balances={balances}
          />
        )}
      </div>
    </>
  );
}

export default Dashboard
