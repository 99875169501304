import React from 'react'
import "./StartFreeTrial.css"
import { useState, useEffect } from "react";
import google from "../../Assets/google.svg"
import { useForm } from "react-hook-form";
import axios from "axios";

function StartFreeTrial(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();

const createUser = async (data)=> {
  console.log(data);
  props.setEmail(data.email);

  const backendUrl = process.env.REACT_APP_BACKEND_URL; // Get backend URL from environment variable
  try {
    const response = await axios.post(`${backendUrl}/users/`, {
      email: data.email,
      username: data.firstName,
      password: data.password,
    });
    // setLoading(false);
    console.log("Response:", response.data);
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Error Status:", error.response.status);
      console.error("Error Data:", error.response.data);
      // setLoading(false);
    } else if (error.request) {
      // Request was made but no response received
      console.error("No response received:", error.request);
      // setLoading(false);
    } else {
      // Something else caused the error
      console.error("Error:", error.message);
      // setLoading(false);
    }
  }
}






  return (
    <div style={{ background: "black" }}>
      <div className="container col-xl-10 col-xxl-8 px-4 py-3 text-white">
        <div className="row align-items-start g-lg-5 py-5">
          <div className="col-lg-6 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">
              Start Your Trading with a Free Trial
            </h1>
            <p className="col-lg-10 fs-4">
              Experience the full power of our bot totally free for 30 days.
            </p>
            <p className="col-lg-10 fs-4">
              No payment needed. We won’t ask for your credit card. We are that
              certain that after 1 month you will beg to continue!
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-6">
            <form onSubmit={handleSubmit(createUser)} className="p-4 p-md-5 border borderRadius bg-dark border">
              <div className="mb-2">
                <h3 className="fw-bold">Account Creation Form</h3>
              </div>
              <div className="mb-3 d-flex">
                <div>
                <label for="exampleInputEmail1" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control"
                  {...register("firstName", {
                    required: "First Name is required"
                  })}
                />
                </div>
                <div className="ms-2">
                <label for="exampleInputEmail1" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                />
                </div>

              </div>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  {...register("email", {
                    required: "Email is required",pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, // regex for basic email format
                      message: "Enter a valid email address",
                    },
                  })}
                  className="form-control"
                />
                <div className='text-danger small'>{errors.email?.message}</div>
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  {...register("password", {
                    required: "Password is required"
                  })}
                />
                <div className='text-danger small'>{errors.password?.message}</div>
              </div>
              <button
                className="w-100 btn btn-lg border text-white mb-4"
                type="submit"
              >
                Sign up
              </button>
              <div className="text-center mb-3">
                or sign up with
              </div>
              <button
                className="w-100 btn btn-lg border text-white"
                
              >
                <img src={google} alt="" className="me-2 mb-1" />
                <span  className="fw-bold" style={{color: "rgb(28, 158, 181)"}}>Google</span>
              </button>
              
              <hr className="my-4" />
              <small className="text-muted">
                By Signing up, you agree to the terms of use.
              </small>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartFreeTrial
